import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
import Props from 'components/Props';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=LinkText wrap=flex",
        "componentName": "LinkText",
        "wrap": "flex"
      }}>{`<LinkText href="http://chameleon.mediahuis.be">
  Go home
</LinkText>
<LinkText href="http://chameleon.mediahuis.be" branded>
  I am branded
</LinkText>
<LinkText href="http://chameleon.mediahuis.be" branded disabled>
  I am disabled
</LinkText>
`}</code></pre>
    <h2 {...{
      "id": "props",
      "style": {
        "position": "relative"
      }
    }}>{`Props`}</h2>
    <Props identifier="LinkText" mdxType="Props" />
    <h2 {...{
      "id": "as",
      "style": {
        "position": "relative"
      }
    }}>{`As`}</h2>
    <p><inlineCode parentName="p">{`default: a`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<LinkText as="button" onClick={() => alert('Kiekeboe')}>
  I'm a button visualized as a link
</LinkText>
`}</code></pre>
    <h2 {...{
      "id": "branded",
      "style": {
        "position": "relative"
      }
    }}>{`Branded`}</h2>
    <p><inlineCode parentName="p">{`default: false`}</inlineCode></p>
    <p>{`By default tehe LinkText has a supportive blue color. You can make it brand
specifix by using the branded prop.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=flex",
        "wrap": "flex"
      }}>{`<LinkText>Not so branded</LinkText>
<LinkText branded>Sexy and branded</LinkText>
`}</code></pre>
    <h2 {...{
      "id": "disabled",
      "style": {
        "position": "relative"
      }
    }}>{`Disabled`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`Marking a LinkText as `}<inlineCode parentName="p">{`disabled`}</inlineCode>{`, will disable all interaction with the element.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=flex",
        "wrap": "flex"
      }}>{`<LinkText disabled>Not so clicky</LinkText>
`}</code></pre>
    <h2 {...{
      "id": "tabindex",
      "style": {
        "position": "relative"
      }
    }}>{`TabIndex`}</h2>
    <p><inlineCode parentName="p">{`default: 0`}</inlineCode></p>
    <p>{`Native html tabindex attribute.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=flex",
        "wrap": "flex"
      }}>{`<LinkText tabIndex="1">First</LinkText>
<LinkText tabIndex="3">Third</LinkText>
<LinkText tabIndex="2">Second</LinkText>
<LinkText tabIndex="4">Fourth</LinkText>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      